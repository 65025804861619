<template>
  <resource-page resource="order" title="Orders">
    <template #default="{ grid }">
      <resource-table v-if="grid != null" :resource="resource" :grid="grid">
        <template #column-total_paid="{ row }">
          <div
            :title="row.total_paid"
            class="text-end"
            style="padding-right: 10px"
          >
            {{ $filters.currencyEUR(row.total_paid) }}
          </div>
        </template>
        <template #column-initial_amount="{ row }">
          <div
            :title="row.initial_amount"
            class="text-end"
            style="padding-right: 10px"
          >
            {{ $filters.currencyEUR(row.initial_amount) }}
          </div>
        </template>
        <template #column-delivery_name="{ row }">
          <div class="text-center">
            <img
              v-if="row.delivery_name === 'Express'"
              src="/media/icons/flash.png"
              alt="Express shipping"
              title="Express shipping"
              width="24"
            />

            <img
              v-else
              src="/media/icons/turtle-facing-right.png"
              alt="Standard shipping"
              title="Standard shipping"
              width="24"
            />
          </div>
        </template>
        <template #column-marks="{ row }">
          <div v-if="row.marks != null" class="text-center">
            <div v-for="mark in row.marks" :key="mark.id" class="m-1">
              <order-mark-badge
                :mark="mark"
                :is-deletable="false"
                @delete="() => deleteMark(mark.id)"
              />
            </div>
          </div>
        </template>
        <template #column-status="{ row }">
          <div v-if="row.status != null" class="text-center">
            <span class="badge" :style="'background-color:' + (row.status.color || '#000')">
              {{ row.status.title || row.status.name }}
            </span>
          </div>
        </template>
      </resource-table>
    </template>
  </resource-page>
</template>
<script lang="ts">
import ResourcePage from "@/modules/resources/views/ResourcePage.vue";
import { defineComponent } from "vue";
import ResourceTable from "@/modules/resources/components/ResourceTable/ResourceTable.vue";
import OrderMarkBadge from "@/modules/orders/widgets/OrderMarkBadge.vue";
import { setPageTitle } from "@/core/helpers/dom";

export default defineComponent({
  name: "OrdersResourcePage",
  components: { ResourcePage, ResourceTable, OrderMarkBadge },
  setup() {
    const resource = "order";
    const title = "Orders";

    setPageTitle("Orders");

    return {
      resource,
      title,
    };
  },
});
</script>

<style scoped>
.table-responsive {
  min-height: 250px;
}
</style>

<style>
.multiselect-option {
  font-size: 13px;
  line-height: 1rem;
}
.multiselect-single-label {
  font-size: 13px;
  padding-right: 27px;
  padding-left: 10px;
  line-height: 1rem;
}
.multiselect-clear {
  width: 28px;
}
.multiselect-input {
  font-size: 14px;
}
.multiselect-no-options {
  font-size: 14px;
}
</style>
