
import { defineComponent, PropType } from "vue";
import { OrderMark } from "@/modules/common/interfaces/OrderMarksResponse.interface";

export default defineComponent({
  name: "OrderMarkBadge",
  props: {
    mark: {
      type: Object as PropType<OrderMark>,
      required: true,
    },
    isDeletable: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
  },
  emits: ["delete"],
});
