
import ResourcePage from "@/modules/resources/views/ResourcePage.vue";
import { defineComponent } from "vue";
import ResourceTable from "@/modules/resources/components/ResourceTable/ResourceTable.vue";
import OrderMarkBadge from "@/modules/orders/widgets/OrderMarkBadge.vue";
import { setPageTitle } from "@/core/helpers/dom";

export default defineComponent({
  name: "OrdersResourcePage",
  components: { ResourcePage, ResourceTable, OrderMarkBadge },
  setup() {
    const resource = "order";
    const title = "Orders";

    setPageTitle("Orders");

    return {
      resource,
      title,
    };
  },
});
