<template>
  <div class="badge" :style="'background-color:' + (mark.color || '#000')">
    <span class="align-middle">{{ mark.name }}</span>
    <button
      v-if="isDeletable"
      type="button"
      class="btn-close btn-close-white align-middle"
      @click="$emit('delete')"
    ></button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { OrderMark } from "@/modules/common/interfaces/OrderMarksResponse.interface";

export default defineComponent({
  name: "OrderMarkBadge",
  props: {
    mark: {
      type: Object as PropType<OrderMark>,
      required: true,
    },
    isDeletable: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
  },
  emits: ["delete"],
});
</script>

<style scoped></style>
